<template>
  <div>
    <div class="headerPopover">

        <p style="border-radius: 5px 5px 0 0;min-width: 40rem;font-size: 16px;background: #6081e8; padding-top: 1px;color: white; padding-left: 10px;margin-bottom: 5px;padding-right: 10px;" >
          Ultimas Compras - {{ product.produtoDescricao }}
        </p>

        <p style="padding: 0 10px;" v-html="product.ultima_compra_html"></p>
        <p style="padding: 0 10px;" v-if="!product.ultima_compra_html" class="loader">
            <img src="/images/loader.svg" style="width: 30px;margin-right: 11px;"/>
            <span>Carregando</span>
        </p>
     
    </div>
  </div>
</template>

<script>

export default {
  props: ["product"],
  methods: {
    close(){
      this.$emit("closePopover");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  created() {
  }
};
</script>
<style lang="scss" scoped>

p.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
}

.headerPopover {
  background-color: white;
  width: fit-content;
  border-radius: 5px;
  box-shadow: 0px 0px 11px 6px rgba(0,0,0,0.09);
}

table.last-buy {
    thead {
      color: #00000082;
      text-transform: uppercase;
      font-size: 0.8rem;			
      opacity: 0.9;
    }
    tbody {
      opacity: 0.7;
      tr {
        
        td {
          width: 20px;
          font-size: 0.8rem;
        }

        &:nth-child(even) {
          td {
    
            background-color: #FFFFFF;
          }
        }
        &:nth-child(odd) {
          td {
            background-color: #F3F3F3;
          }
        }
      }
    }
}

</style>